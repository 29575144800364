import React from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
  const getOpts = () => {
    const screenWidth = window.innerWidth;
    let height, width;

  

    if (screenWidth <= 600) {
      // Mobile
      height = '300';
      width = '320px';
    } else if (screenWidth <= 768) {
      // Screen with a width of 768px
      height = '300';
      width = '600px'; 
    } else if (screenWidth <= 1024) {
      // Tablet
      height = '400';
      width = '750px';
    } else {
      // Desktop
      height = '390';
      width = '640';
    }

    
 

    return { height, width };
  };

  const opts = getOpts();

  return (
    <div className="max-w-2xl mx-auto mb-8 container">
      <div className="youtube-player relative " >
        <YouTube videoId={videoId} opts={opts} className=" w-full h-full" />
      </div>
    </div>
  );
};

export default VideoPlayer;
