import React from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Newsletter from "../home/Newsletter";

import { RiGlobalLine } from "react-icons/ri";
import { MdOutlineSupportAgent } from "react-icons/md";
import { MdDiversity2 } from "react-icons/md";
import { FcProcess } from "react-icons/fc";
import { GiProcessor } from "react-icons/gi";

import image from '../assets/workforce.png'

const Workforce = () => {
  return (
    <div>
      <div className="">
        <div className="  py-16  px-4  bg-[#1bafd0]  ">
          <div className=" flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-4 text-white md:text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-4xl text-custom-blue 2xl:py-6 xl:text-[60px] xl:leading-[80px] ">
              Elevate Your Team Across Industries
              </h3>
              <p className="text-md py-4 text-white md:text-center lg:text-left lg:text-xl  ">
                Explore dynamic workforce solutions at asiNetwork. We specialize
                in delivering top-tier talent and tailored services across the
                Hospitality, IT, and Human Resources sectors.
              </p>

              <div className="flex focus:outline-none flex-col text-sm py-2 lg:mt-4 md:flex-row sm:gap-x-10 lg:flex-row space-y-4 lg:space-y-0 lg:space-x-0 justify-center lg:justify-start">
          <Link to={'/contact-us'}>     <motion.button
                  className="bg-[#6967ce] text-white px-8 py-4 rounded-[30px]  "
                  whileHover={{
                    scale: 1.05,
                    color: "black",
                    background: "white",
                  }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                >
                  Let's Connect
                </motion.button></Link> 
              </div>
            </div>

            <img
            src={image}
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt="workforce"
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 max-w-[350px] sm:max-w-[550px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto  py-16 ">
        <h3 className="text-2xl xl:pl-10 lg:pl-0 sm:text-center  lg:text-3xl  py-8 font-semibold xl:text-[60px] xl:leading-[80px]">
          Why Choose Our Workforce Solutions?
        </h3>

        <div className="flex flex-wrap   justify-between  gap-4  lg:space-x-0  lg:gap-x-12 xl:gap-x-0 lg:gap-y-8 lg:justify-center  xl:justify-between   2xl:justify-center 2xl:gap-12 items-center py-8">
          <div className="bg-[#fd636b] rounded-2xl  w-[250px] h-[300px] shadow-lg  p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <RiGlobalLine className="text-white" size={50} />
            <p className="text-white text-xl font-semibold py-2 ">
              Industry-Specific Expertise
            </p>
            <p className="text-sm text-white ">
            Access specialized professionals with expertise in Hospitality, IT, and HR.
            </p>
          </div>

          <div className="bg-[#ffb900]  rounded-lg w-[250px]  h-[300px] shadow-lg   p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <MdOutlineSupportAgent className="text-white" size={50} />
            <p className="text-white text-xl font-semibold py-2 ">
            Flexible Staffing Models
            </p>

            <p className="text-sm text-white">
            Enjoy flexibility in staffing solutions that adapt to industry-specific requirements.
            </p>
          </div>

          <div className="rounded-lg bg-gray-500  w-[250px] h-[300px] shadow-lg   p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <MdDiversity2 className="text-white" size={50} />

            <p className="text-white text-xl font-semibold py-2">
            Swift Team Integration Support
            </p>

            <p className="text-sm text-white ">
            Ensure rapid integration of skilled professionals tailored to industry demands. 
            </p>
          </div>

          <div className="bg-[#6967ce]   rounded-lg w-[250px] h-[300px] shadow-lg  p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <GiProcessor className="text-white" size={50} />

            <p className="text-white text-xl font-semibold py-2 ">
            Quality Service Assurance
            </p>

            <p className="text-sm text-white ">
            Guarantee the highest standard of service with our high-caliber staffing.
            </p>
          </div>
        </div>
      </div>

      {/* how it works */}

      <div className="py-12 lg:py-16">
        <div className=" flex-col-reverse   lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
          <img
            src="https://img.freepik.com/free-vector/recruit-agent-analyzing-candidates_74855-4565.jpg?size=626&ext=jpg&uid=R89088614&ga=GA1.1.159543150.1689348213&semt=sph"
            className=" object-cover xl:w-[500px] 2xl:w-[600px]  lg:w-[440px] w-full px-4  "
            alt=""
          />

          <div className=" py-8 lg:py-0 lg:w-1/2  ">
            <h3 className="py-2 text-gray-900 sm:text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-2xl xl:text-[60px] xl:leading-[80px]">
              How it Works
            </h3>
            <p className="text-base text-gray-700 text-justify  lg:text-left    text-custom-gray">
              <li>	Discuss industry-specific requirements and tailor solutions in a consultation.</li>
              <li>Design solutions catering to the unique demands of your industry.</li>
              <li>	Experience efficient onboarding for swift integration of industry-specific team members.</li>
              <li>	Benefit from ongoing oversight ensuring excellence in service delivery consistently.</li>
              <li>	Receive support for seamless integration, ensuring efficiency and effective industry-specific solutions.</li>
            </p>
          </div>
        </div>
      </div>

      {/* newsletter */}

      <Newsletter />
    </div>
  );
};

export default Workforce;
