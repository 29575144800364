import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PopupWidget } from "react-calendly";
import './i18n'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <React.Suspense fallback="loading...">

   
    <App />

    <PopupWidget
url="https://calendly.com/arvind-2K0"


rootElement={document.getElementById("root")}
text="Book Consultancy"
textColor="#ffffff"
color="#00a2ff"
/>

</React.Suspense>
 </React.StrictMode>

);


