import React from 'react';

const Call = () => {
  const phoneNumber = '+918859505051'; 

  return (
    <div className="fixed bottom-20 left-1 p-3 rounded-full cursor-pointer">
      <a href={`tel:${phoneNumber}`}>
        <img
          src="https://cdn-icons-png.flaticon.com/128/3059/3059561.png"
          alt="Call"
          className="w-16 h-16"
        />
      </a>
    </div>
  );
};

export default Call;
