import React from "react";
import { motion, useAnimation } from "framer-motion";

import { Link } from "react-router-dom";

import whatsappBanner from '../assets/w-banner.png'

import {  useTranslation } from "react-i18next";

const Hero = () => {

  const { t } = useTranslation();

  const {line1, line2} = t('description');


  const controls = useAnimation();

  const animateHero = async () => {
    await controls.start({ x: 0, opacity: 1, transition: { duration: 0.5 } });
  };

  return (
    <section className=" pt-12 ">

<div className= " mx-auto container max-w-[350px] sm:max-w-[550px] md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1100px] 2xl:max-w-[1300px] ">
 <Link to={'/whatsapp-marketing-service'}> <img src={whatsappBanner} className="object-contain lg:object-cover" alt="whatsapp" /> </Link>
</div>



      <div className="container mb-8 xl:mb-0   flex-col-reverse  mx-auto lg:max-h-screen  max-w-[350px] sm:max-w-[550px] md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1100px] 2xl:max-w-[1300px]  flex  lg:flex lg:flex-row justify-between items-center lg:gap-4">
        {/* Left side */}
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1.5,
            x: { type: "spring", stiffness: 60 },
            opacity: { duration: 2 },
            ease: "easeIn",
            duration: 1,
          }}
          className="w-full  px-4   lg:w-1/2  "
        >
          <motion.h1 className="text-2xl py-2 font-extrabold xl:text-[60px] md:text-center lg:text-left lg:text-2xl  sm:mb-0 xl:leading-[80px]">
            Empowering Your Workforce
          </motion.h1>

          <p className="text-sm py-2 xl:text-lg lg:text-left lg:text-sm text-md xl:py-4 text-justify">
  Embrace the future with asiNetwork. As your dedicated partner in{" "}
  <span className="font-bold">Hospitality & IT Workforce Solutions</span>, we
  specialize in{" "}
  <span className="font-bold">
    Global Payroll Solutions, Hire Employees Abroad, and Digital Transformation
    Consultancy
  </span>. Welcome to a future-ready approach to business success
</p>


          <div className="flex flex-col text-sm py-2 md:flex-row sm:gap-x-10 lg:flex-row space-y-4 lg:space-y-0 lg:space-x-0 justify-center lg:justify-start">
            <Link to={"/contact-us"}>
              <motion.button
                className="bg-[#6967ce] text-white px-8 py-4 rounded-[30px] border-2 border-gray-200  "
                whileHover={{
                  scale: 1.05,
                  color: "black",
                  background: "white",
                }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
              >
                Let's Connect
              </motion.button>
            </Link>
          </div>
        </motion.div>

        {/* Right side */}
        <motion.div
          initial={{ x: -300, opacity: 1 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1,
            x: { type: "spring", stiffness: 60 },
            opacity: { duration: 2 },
            ease: "easeIn",
            duration: 2,
          }}
          onAnimationComplete={animateHero}
          className="w-full lg:w-1/2     lg:h-[500px]"
        >
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src="https://img.freepik.com/free-vector/forming-team-leadership-concept-illustration_114360-13973.jpg?w=740&t=st=1701251734~exp=1701252334~hmac=afe01fcc20cbdaac1a0f5d567022eb5f2137385a8df1408f84e4942d485b9875"
            alt="Hero"
            className="object-cover w-full "
          />
        </motion.div>
      </div>

      {/* scrolling flags */}
      <div></div>
    </section>
  );
};

export default Hero;
