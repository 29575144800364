import React from 'react';
import { Link } from 'react-router-dom';

const PricingTable = ({ title, price, features }) => {
  return (
    <div className="w-[350px] h-auto lg:h-[1400px] border border-blue-500 rounded-lg cursor-pointer hover:bg-blue-500 hover:text-white">
      <div className="px-10 py-10">
        <div className=" font-bold text-2xl mb-2 text-center ">{title}</div>
        <p className=" text-4xl mb-8 font-semibold text-center ">{price}</p>
       
        <ul className="list-disc">
          {features.map((feature, index) => (
            <li key={index} className=" text-base py-2  ">{feature}</li>
            
          ))}
        </ul>

        <div className='flex justify-center mt-16 '>

    <Link to={'/contact-us'}>  <button className="bg-black text-white py-2 px-8 rounded  transition-colors ">
        Enquire Now
      </button>
      </Link>  

        </div>

      

      </div>
    </div>
  );
};

export default PricingTable;
