
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home'
import Navbar from './components/home/Navbar'
import Footer from './components/home/Footer';
import Hire from './components/services/Hire';
import Contact from './components/pages/Contact';
import Payroll from './components/services/Payroll';
import Digital from './components/services/Digital';
import It from './components/services/It';
import Internship from './components/services/Internship';
import Workforce from './components/services/Workforce'
import WhatsApp from './components/services/Whatsapp';
import Call from './components/CTA/Call';
import ScrollToTop from './components/ScrollToTop';
import WhatsappWidget from './components/CTA/WhatsappWidget';
import WhatsAppWidget from './components/CTA/WhatsappWidget';
import Webdev from './components/services/Webdev';

function App() {
  return (

<Router>

  <ScrollToTop />

  <Navbar />

<Routes>

<Route path='/' element={<Home />} />

<Route path='/hire-emplyoees-abroad' element={<Hire />} />
<Route path='/global-payroll-solution' element={<Payroll />} />
<Route path='/digital-transformation-consultancy' element={<Digital />} />
<Route path='/workforce-solution' element={<Workforce />} />
<Route path='/contact-us' element={<Contact />} />
<Route path='/it-infra-management' element={<It />} />
<Route path='/internship-program' element={<Internship />} />

<Route path='/whatsapp-marketing-service' element={<WhatsApp />} />

<Route path='/web-development' element={<Webdev />} />


</Routes>

<Footer />

<WhatsAppWidget />
<Call />

</Router>



  );
}

export default App;
