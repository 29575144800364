import React from "react";
import { FaCode, FaMobile,FaFileSignature, FaDatabase, FaHireAHelper } from "react-icons/fa";
import { RiGlobalFill } from "react-icons/ri";
import ServicesCard from "./ServicesCard";
import { FaComputer } from "react-icons/fa6";

import { SiStaffbase } from "react-icons/si";
import { LiaDigitalOcean } from "react-icons/lia";

const Services = () => {
  return (
    <section className="py-16 container mx-auto max-w-[350px] sm:max-w-[550px] md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1100px] 2xl:max-w-[1300px]">

      <h4 className="text-center font-bold text-4xl lg:text-[60px] lg:leading-[80px] py-8 w-full">Our Services</h4>

      <div className="p-4 flex flex-wrap justify-between lg:justify-center lg:gap-28 xl:gap-0 xl:justify-between w-full">

        <ServicesCard
          icon={<FaHireAHelper className='text-[#1bafd0]' size={48} />}
          title="Hire Employees Abroad"
          description="Facilitate international hiring processes for a diverse and skilled workforce."
         
        />
        <ServicesCard
          icon={<RiGlobalFill className='text-[#fd636b]' size={48} />}
          title="Global Payroll Services"
          description="Efficiently manage payroll on a global scale for streamlined financial operations."
        />
   
        <ServicesCard
          icon={<SiStaffbase className="text-[#ffb900]" size={48} />}
          title="Workforce Solutions"
          description="Providing trained hospitality staff to enhance service excellence for businesses."
        />
    
     
        <ServicesCard
          icon={<FaComputer  className="text-[#676767]" size={48} />}
          title="Digital Transformation Consultancy"
          description="Guiding businesses through innovative digital strategies for successful transformation and growth."
        />
        <ServicesCard
          icon={<FaFileSignature  className="text-blue-500" size={48} />}
          title="IT Infra Management & Rental Services"
          description="Efficiently manage and rent IT infrastructure for seamless business operations."
        />


<ServicesCard
          icon={< LiaDigitalOcean  className="text-red-500" size={48} />}
          title="Internship Programe"
          description="Efficiently manage and rent IT infrastructure for seamless business operations."
        />








      </div>

    </section>
  );
};

export default Services;
