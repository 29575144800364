import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import logo from "../assets/logo.png";
import { IoIosCall } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-12   ">
      <div className="container mx-auto max-w-[350px] sm:max-w-[550px] md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1100px]  2xl:max-w-[1300px]">
        <div className="pt-24 flex  flex-col gap-y-8 lg:flex-row  justify-between lg:gap-x-28 xl:gap-x-36 2xl:gap-x-56 ">
          
          
          {/* Section 1 */}

          <div className="w-full md:w-[300px]">
            <img src={logo} alt="logo" />
            <p className="text-lg w-[300px] py-4 text-justify">
              Empowering a Future of Innovation and Inclusivity, where
              Boundaries are Transcended, Ideas Flourish, and Every Individual
              Finds Purpose and Success.
            </p>

            <div className="flex pt-6 gap-4 cursor-pointer">
         <Link to={'/https://www.facebook.com/'}>   <FaFacebook size={30} className="text-blue-500" /> </Link>  
         <Link to={'https://www.instagram.com/'}>    <FaInstagram size={30} className="text-blue-500" />  </Link> 
         <Link to={'https://www.linkedin.com/'}>    <FaLinkedin size={30} className="text-blue-500" />  </Link> 
            </div>
          </div>

          {/* Section 2 */}
          <div className="w-full lg:w-1/4">
            <h5 className="text-2xl font-bold mb-4">Our Services</h5>
            <ul>

           <Link to={'/hire-emplyoees-abroad'}>  <li className="text-base py-1">Hire Employees Abroad</li> </Link> 

           <Link to={'/global-payroll-solution'}>   <li className="text-base py-1">Global Payroll Solutions</li> </Link> 

           <Link to={'/workforce-solution'}>    <li className="text-base py-1">Workforce Solutions</li> </Link> 
           
           
           <Link to={'/digital-transformation-consultancy'}>   <li className="text-base py-1">
                Digital Transformation Consultancy
              </li> </Link> 

              <Link to={'/it-infra-management'}>   
                <li className="text-base py-1">
                IT Infra Management & Rental Services
              </li> </Link> 

              <Link to={'/internship-program'}>      <li className="text-base py-1">
             Internship Programme
              </li> </Link> 
            </ul>
          </div>

          {/* Section 3 */}
          <div className="w-full lg:w-1/4 ">
            <h5 className="text-2xl font-bold mb-4"> Connect With Us</h5>
       

              <div className="flex gap-2 py-1">

              <IoIosCall size={30} className="text-blue-500" />
              <p className="text-base py-1">   (+91) 8868914029</p>
              </div>

              <div className="flex gap-2 py-1">

<CiMail  size={30} className="text-blue-500" />
<p className="text-base py-1"> connect@asinetwork.in</p>
</div>


<div className="flex gap-2 py-1">

<FaLocationArrow   size={30} className="text-blue-500" />
<p className="text-base py-1">  DEHRADUN OFFICE <br />
2nd Floor, OM Tower. Sahastradhara Road, Near IT Park Dehradun, Uttrakhand, India- 248001</p>
</div>

<div className="flex gap-2 py-1">

<FaLocationArrow   size={30} className="text-blue-500" />
<p className="text-base py-1">  HARIDWAR OFFICE <br />
2nd Floor 46, Vivek Vihar, Ranipur Mor, Haridwar, Uttrakhand, India- 249407</p>
</div>


           

           
          </div>

          {/* Section 4 */}
        </div>

        <hr className="border-t border-gray-400 my-8 lg:my-16 "  />


        <div>
      <p className="text-center"> Copyright © A S International. All Rights Reserved.</p>
    </div>
      </div>
    </footer>
  );
};

export default Footer;
