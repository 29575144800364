import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Newsletter from "../home/Newsletter";
import { FaUserTie } from "react-icons/fa";
import { LuMailOpen } from "react-icons/lu";
import VideoPlayer from "../video/VideoPlayer";
import { PiCursorClick } from "react-icons/pi";
import WhatsappNewsletter from "../home/WhatsappNewsletter";
import Modal from "react-modal";
import { useForm, ValidationError } from '@formspree/react';



const Digital = () => {
    const [state, handleSubmit] = useForm("mbjvvboq");
    const [modalIsOpen, setModalIsOpen] = useState(false);
  
    useEffect(() => {
      setModalIsOpen(true);
    }, []);
  
    if (state.succeeded) {
      return (
        <h2 className="text-4xl text-purple-400-500 text-center py-36 font-bold">
          Thanks for contacting Us. We Will Get Back to You Soon!
        </h2>
      );
    }
  

    const videoIds = ['EzXHgNqYniY'];

  return (
    <div>
      <div className="">
    
     {/* banner */}

        <div className="  py-16 lg:py-36  px-4  bg-[#028a0f]  ">
          <div className=" flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-4 text-white md:text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-4xl text-custom-blue 2xl:py-6 xl:text-[60px] xl:leading-[80px] ">
                Your Complete WhatsApp Marketing Partner
              </h3>

              <li className="text-md py-1 text-white md:text-center lg:text-left lg:text-xl  ">
                Broadcast Promotional Offers to Unlimited Users
              </li>
              <li className="text-md py-1 text-white md:text-center lg:text-left lg:text-xl  ">
                Sell your Products on WhatsApp using Catalogs
              </li>
              <li className="text-md py-1 text-white md:text-center lg:text-left lg:text-xl  ">
                Automate Messages via Integrations
              </li>
              <li className="text-md py-1 text-white md:text-center lg:text-left lg:text-xl  ">
                Enable Multi-Agent Live Chat for Customer Support
              </li>
              <li className="text-md py-1 text-white md:text-center lg:text-left lg:text-xl  ">
                Build Chatbots & Solve for 24 x 7 Support & Engagement
              </li>

              <ul>
                <li className="text-md pt-4 font-bold text-white md:text-center lg:text-left lg:text-xl  ">
                  ⚡️Official Channel Partner of AiSensy
                </li>
              </ul>
              <div className="flex focus:outline-none flex-col text-sm py-2 lg:mt-4 md:flex-row sm:gap-x-10 lg:flex-row space-y-4 lg:space-y-0 lg:space-x-0 justify-center lg:justify-start">
          <Link to={'/contact-us'}>     <motion.button
                  className="bg-white text-[#028a0f] px-8 py-4 rounded-[30px]  "
                  whileHover={{
                    scale: 1.05,
                    color: "black",
                    background: "white",
                  }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                >
                  Let's Connect
                </motion.button></Link> 
              </div>
            </div>

            <img
              src="https://unicorn-cdn.b-cdn.net/ffa5f423-330f-4d27-b9d4-d78d221b3647/aisensy-whatsapp-api-integrations.png?width=790&height=552"
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt=""
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 max-w-[350px] sm:max-w-[550px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto  py-16 ">
        <h3 className="text-2xl xl:pl-10 lg:pl-0 sm:text-center  lg:text-3xl  py-8 font-semibold xl:text-[52px] xl:leading-[80px]">
          Why Choose Our Whatsapp Marketing Service?
        </h3>

        <div className="flex flex-wrap   justify-between  gap-4  lg:space-x-0  lg:gap-x-12 xl:gap-x-0 lg:gap-y-8 lg:justify-center  xl:justify-between   2xl:justify-center 2xl:gap-12 items-center py-8">
          <div className="bg-[#028a0f] text-white rounded-2xl  w-[250px] h-[300px] shadow-lg  p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <LuMailOpen className="text-white mx-auto" size={150} />
            <p className="text-white text-center text-xl font-semibold py-2 ">
              98% Open Rates
            </p>
            {/* <p className="text-sm text-white ">
              Access comprehensive digital expertise from consultancy to
              marketing services.
            </p> */}
          </div>

          <div className="bg-[#fff]  rounded-lg w-[250px]  h-[300px] shadow-lg   p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <PiCursorClick  className="text-black mx-auto" size={150} />
            <p className="text-black text-center text-xl font-semibold py-2 ">
              45-60% Click Rates
            </p>

            {/* <p className="text-sm text-white ">
              Benefit from ongoing oversight, ensuring continuous innovation and
              effective marketing.
            </p> */}
          </div>

          <div className="rounded-lg bg-[#028a0f] w-[250px] h-[300px] shadow-lg   p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            < FaUserTie className="text-white mx-auto" size={150} />

            <p className="text-white text-center text-xl font-semibold py-2">
              2.4+ Bn Users
            </p>

            {/* <p className="text-sm text-white ">
              Customize solutions aligning digital transformation and marketing
              strategies seamlessly.
            </p> */}
          </div>

          {/* <div className="bg-[#6967ce]   rounded-lg w-[250px] h-[300px] shadow-lg  p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <GiProcessor className="text-white" size={50} />

            <p className="text-white text-xl font-semibold py-2 ">
              Efficient Implementation Process
            </p>

            <p className="text-sm text-white ">
              Experience an efficient implementation process for swift digital
              integration.
            </p>
          </div> */}
        </div>
      </div>



  {/* video */}


  <div className="mx-4 px-4 max-w-[350px] sm:max-w-[550px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container md:mx-auto  ">
  <div className="  flex flex-col items-center justify-center">
    
      <div className="flex flex-wrap justify-center gap-4">
        {videoIds.map((videoId) => (
          <VideoPlayer key={videoId} videoId={videoId} />
        ))}
      </div>
    </div>
</div>
 



      {/* how it works */}

      <div className=" ">
        <div className=" flex-col-reverse   lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
          <img
            src="https://unicorn-cdn.b-cdn.net/c72dca37-abfa-4e4a-b42a-b7767288cc0f/promotional-messages-on-whatsapp.png?width=738&height=1044"
            className=" object-cover xl:w-[500px] 2xl:w-[600px]  lg:w-[440px] w-full px-4  "
            alt=""
          />

          <div className="  lg:w-1/2  ">
            <h3 className="py-2 text-gray-900 sm:text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-2xl  xl:text-[40px] xl:leading-[50px] ">
              Broadcast Promotional Messages (Officially)
            </h3>
            <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
              Well, WhatsApp recently allowed Businesses to Broadcast and
              Automate Promotional Messages via WhatsApp Business API <br />
              You can Now Send Exciting Offers, Discount Coupon Code and
              Festival Wishes without getting your Number blocked!
            </p>
          </div>
        </div>
      </div>

      {/* sell your ptoducts */}

      <div className=" lg:pb-16">
        <div className=" flex-col-reverse   lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
          <div className="  lg:py-0 lg:w-1/2  ">
            <h3 className="py-2 text-gray-900 sm:text-center lg:text-left xl:py-4  lg:px-0  font-semibold text-2xl lg:text-2xl  xl:text-[40px] xl:leading-[50px] ">
              Sell your Products on WhatsApp
            </h3>
            {/* <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
            Well, WhatsApp recently allowed Businesses to Broadcast and Automate Promotional Messages via WhatsApp Business API <br />

You can Now Send Exciting Offers, Discount Coupon Code and Festival Wishes without getting your Number blocked!


            </p> */}

<div>



            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
              Drag and Drop CHATBOTS & Catalog Builder
            </li>
            <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
              Build your Own Chatbot Flows your Way! Easy-to-use Chatbot &
              Catalog Flow builder to build your conversational journey
            </p>

            </div>

            <div>



            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
              Drag and Drop CHATBOTS & Catalog Builder
            </li>
            <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
              Build your Own Chatbot Flows your Way! Easy-to-use Chatbot &
              Catalog Flow builder to build your conversational journey
            </p>

            </div>


            <div>



            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
              Drag and Drop CHATBOTS & Catalog Builder
            </li>
            <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
              Build your Own Chatbot Flows your Way! Easy-to-use Chatbot &
              Catalog Flow builder to build your conversational journey
            </p>

            </div>


          </div>

          <img
            src="https://unicorn-cdn.b-cdn.net/32352181-be6f-4100-8911-bd403831b035/flow-builder-banner.png"
            className=" object-cover xl:w-[500px] 2xl:w-[600px]  lg:w-[440px] w-full px-4  "
            alt=""
          />
        </div>
      </div>


       {/* runs clicks on whatsapp */}

       <div className="py-8 lg:py-16">
        <div className=" flex-col-reverse   lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
     

        <img
            src="https://unicorn-cdn.b-cdn.net/57b54efc-1dca-4407-b9a1-a5a8554d6215/group-177-(1).png?width=690&height=468"
            className=" object-cover xl:w-[500px] 2xl:w-[600px]  lg:w-[440px] w-full px-4  "
            alt=""
          />
     
     
          <div className="  lg:py-0 lg:w-1/2  ">
            <h3 className="py-2 text-gray-900 sm:text-center lg:text-left xl:py-4  lg:px-0  font-semibold text-2xl lg:text-2xl  xl:text-[40px] xl:leading-[50px] ">
            Run Click to WhatsApp Ads for 5X Leads
            </h3>
         <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
         Run Ads on Facebook & Instagram that land on WhatsApp. 5X Your lead generations & 2-3 Conversions Instantly!


            </p> 

            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
            Build Click to WhatsApp Ads from AiSensy Platform 
            </li>
       

    
            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
            Get all your Leads Segregated according to Ad Campaigns 
            </li>
     

            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
            Build Chatbot Flows based on Ads or Retarget via Broadcast
            </li>
   

         


          </div>

     
        </div>
      </div>




       {/* smart retargeting sells */}



       <div className="py-8 lg:py-16">
        <div className=" flex-col-reverse   lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
          <div className="  lg:py-0 lg:w-1/2  ">
            <h3 className="py-2 text-gray-900 sm:text-center lg:text-left xl:py-4  lg:px-0  font-semibold text-2xl lg:text-2xl  xl:text-[40px] xl:leading-[50px] ">
            Smart Retargeting for 3X Sales
            </h3>
            <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
            Segregate your Audiences Smartly and Re-Broadcast for Higher Conversions!


            </p>

<div>



            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
            SMART AUTO-SEGREGATION
            </li>
            <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
            AiSensy automatically segregates your broadcast audience by Delivered, Read, Replied Audiences. Run Retargeting Campaigns on the Basis of these filters.
            </p>

            </div>

            <div>



            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
            DRIVE 3X SALES
            </li>
            <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
            Retargeting Campaigns have proven to Increase sales and engagement for Businesses
            </p>

            </div>


    


          </div>

          <img
            src="https://unicorn-cdn.b-cdn.net/32352181-be6f-4100-8911-bd403831b035/flow-builder-banner.png"
            className=" object-cover xl:w-[500px] 2xl:w-[600px]  lg:w-[440px] w-full px-4  "
            alt=""
          />
        </div>
      </div>



  {/* get whatsapp bussiness api */}


  <div className="py-8 lg:py-16">
        <div className=" flex-col-reverse   lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
     

        <img
            src="https://unicorn-cdn.b-cdn.net/fa3d6a68-9a20-42c1-93fc-9f8dd509a544/green-tick-verification-in-whatsapp-business-api.png?width=738&height=478"
            className=" object-cover xl:w-[500px] 2xl:w-[600px]  lg:w-[440px] w-full px-4  "
            alt=""
          />
     
     
          <div className="  lg:py-0 lg:w-1/2  ">
            <h3 className="py-2 text-gray-900 sm:text-center lg:text-left xl:py-4  lg:px-0  font-semibold text-2xl lg:text-2xl  xl:text-[40px] xl:leading-[50px] ">
            Get WhatsApp Business API in 10 Minutes
            </h3>
         <p className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray">
         AiSensy Platform is powered by Official Whatsapp Business APIs and is in alignment with all Whatsapp Rules. 


            </p> 

            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
            Get Verified Green Tick on your Whatsapp
            </li>
       

    
            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
            Broadcast Unlimited Notifications everyday
            </li>
     

            <li className="text-base text-gray-700 text-justify  lg:text-justify lg:w-[450px]    text-custom-gray font-bold py-2">
            24x7 Chat Support by AiSensy Team
            </li>
   

         


          </div>

     
        </div>
      </div>



 

 



      {/* newsletter */}

      <WhatsappNewsletter />




{/* modal */}



<Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Message Modal"
        ariaHideApp={false}
        className="max-w-sm mx-auto mt-36 container bg-[#028a0f]  px-8 py-4"
      >
     
        <div className='py-4 '>
    
<form onSubmit={handleSubmit} >




      
    <div className="mb-4">
          <label htmlFor="name" className="block text-white text-md font-medium py-2">
          First  Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-input w-full  lg:w-[300px]   bg-gray-200 p-2 rounded-lg focus:outline-none "
            
          
          />
        </div>

        <div className="mb-4">
          <label htmlFor="company" className="block text-white text-md font-medium py-2">
            Last Name
          </label>
          <input
            type="text"
            id="company"
            name="company"
            className="form-input w-full  lg:w-[300px] bg-gray-200 p-2 rounded-lg focus:outline-none "
           
        
          />
        </div>



   <div className="mb-4">
          <label htmlFor="email" className="block text-white text-md font-medium py-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-input w-full  lg:w-[300px]  bg-gray-200 p-2 rounded-lg  focus:outline-none"
       
      
          />
        </div>

   <div className="mb-4">
          <label htmlFor="phone" className="block text-white text-md font-medium py-2">
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            className="form-input w-full  lg:w-[300px] bg-gray-200 p-2 rounded-lg  focus:outline-none"
           
 
          />
        </div>


        <div className="mb-4">
          <label htmlFor="message" className="block text-white text-md font-medium py-2">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className="form-textarea w-full   lg:w-[300px]   bg-gray-200 p-2 rounded-lg  focus:outline-none"
          placeholder='Write Your Message'
          
  
          ></textarea>
        </div>

<div className='flex justify-center max-w-sm mx-auto container'>

 <button
          type="submit"
          className="bg-white  lg:w-[200px] text-[#028a0f] font-semibold py-3 px-8 rounded-lg  "
        >
    Send Message
        </button>

        </div>
    


      

      </form>


</div>
      </Modal>


      
    </div>
  );
};

export default Digital;
