import React from "react";


import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

const WhatsappNewsletter = () => {





  return (

    <section className="bg-[#028a0f]">

  
    <div className="py-12 lg:py-16  mx-4 px-4 sm:container sm:mx-auto sm:max-w-[550px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] lg:container lg:mx-auto">
      <p className="text-xl   md:text-center tracking-wider lg:text-[36px] font-bold  sm:text-center text-white py-2 lg:text-center">
      Your Customers are on WhatsApp! Are You?
      </p>
      {/* <p className="text-base xl:text-lg tracking-wider  font-bold  sm:text-center text-white py-2">
      Click Contact Us for a hassle-free consultation.
   </p> */}


      <div className="flex lg:flex-row   sm:justify-center py-4">
   
        



<Link to={'/contact-us'}>
<motion.button
  className="px-8 py-3 rounded-3xl border-2 border-gray-200 bg-white"
  whileHover={{ scale: 1.05, color: "black", background: "white" }}
  whileTap={{ scale: 0.95 }}
  transition={{ duration: 0.2, ease: 'easeInOut' }}
>
Get Started Now!
</motion.button>
</Link> 

{/* <Link to={'/contact-us'}>
<motion.button
  className="px-8 py-3 rounded-3xl border-2 border-gray-200 bg-white"
  whileHover={{ scale: 1.05, color: "black", background: "white" }}
  whileTap={{ scale: 0.95 }}
  transition={{ duration: 0.2, ease: 'easeInOut' }}
>
Connect With Us!
</motion.button>
</Link>  */}



      </div>
    </div>

    </section>
  );
};

export default WhatsappNewsletter;
