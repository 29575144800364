import React from 'react';

const ServicesCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white m-4 w-[300px] h-[300px] shadow-md p-6 rounded-lg transition duration-300 transform hover:scale-105 cursor-pointer">
      <div className="flex items-start justify-start mb-4 ">
        {icon}
      </div>
      <h3 className="text-lg font-bold text-gray-800 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

export default ServicesCard;
