import React from "react";
import image from '../assets/apart.png'

const Apart = () => {
  return (
    <section className="bg-[#A7D397]  ">
      <div className="py-12 container mx-auto max-w-[350px] sm:max-w-[550px]  md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1100px] 2xl:max-w-[1300px]">
        <div className=" flex justify-between items-center   flex-wrap">
          <div className=" lg:w-1/2">
            <img
              className="object-cover w-full lg:w-[400px] xl:w-[500px] rounded-s-full "
              src={image}
              alt="what set us apart"
            />
          </div>

          <div className="w-[570px] md:w-full lg:w-1/2 ">
            <h3 className="py-8 sm:text-center lg:text-left lg:py-4 font-semibold text-2xl lg:text-[60px] lg:leading-[80px] text-white">
              What Sets Us Apart?
            </h3>
            <p className=" text-base xl:text-md py-4 leading-5 lg:leading-8 lg:text-[16px]   text-white">
              <li>
                Harnessing global expertise to deliver tailored solutions at a
                local level.
              </li>
              <li>
                Crafting tailored, innovative solutions to drive success across
                diverse industries.
              </li>
              <li>
                Ensuring compliance at every step, guaranteeing reliability and
                peace of mind.
              </li>
              <li>
                Driving efficiency and speed in operations for impactful
                business outcomes.
              </li>
              <li>
                Demonstrating a consistent track record of excellence and client
                success.
              </li>
              <li>
                Dedicated to a client-centric approach, fostering lasting
                satisfaction and partnerships.
              </li>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Apart;
