import React from 'react'
import Hero from './Hero'
import Mission from './Mission'
import Services from './Services'
import Newsletter from './Newsletter'
import Apart from './Apart'
import Testimonials from './Testimonials'
import Whatsapp from './Whatsapp'

const Home = () => {
  return (
    <div>
        <Hero />
      
        <Mission />
        <Services />
       
        <Apart />
        <Testimonials />
        <Newsletter />
    </div>
  )
}

export default Home