import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Newsletter from "../home/Newsletter";
import { FaUserTie } from "react-icons/fa";
import { LuMailOpen } from "react-icons/lu";
import VideoPlayer from "../video/VideoPlayer";
import { PiCursorClick } from "react-icons/pi";
import WhatsappNewsletter from "../home/WhatsappNewsletter";
import { LuWebhook } from "react-icons/lu";
import { MdComputer } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import PricingCard from "./PricingTable";

const Webdev = () => {
  return (
    <div>
      <div className="">
        {/* banner */}

        <div className="  py-16 lg:py-36  px-4  bg-[#fff]  ">
          <div className=" flex-col-reverse  lg:flex-row  flex justify-between items-center space-x-2 flex-wrap max-w-[350px] sm:max-w-[550px]   lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto ">
            <div className=" py-8 lg:py-0 lg:w-1/2  ">
              <h3 className="py-4  md:text-center lg:text-left xl:py-4  lg:px-0 lg:py-8 font-semibold text-2xl lg:text-4xl text-custom-blue 2xl:py-6 xl:text-[60px] xl:leading-[80px] ">
                Elevate Your Online Presence with Expert Web & App Development
              </h3>

              <div className="flex focus:outline-none flex-col text-sm py-2 lg:mt-4 md:flex-row sm:gap-x-10 lg:flex-row space-y-4 lg:space-y-0 lg:space-x-0 justify-center lg:justify-start">
                <Link to={"/contact-us"}>
                  {" "}
                  <motion.button
                    className="bg-blue-500 text-[#fff] px-8 py-4 rounded-[30px]  "
                    whileHover={{
                      scale: 1.05,
                      color: "black",
                      background: "white",
                    }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    Let's Connect
                  </motion.button>
                </Link>
              </div>
            </div>

            <img
              src="https://img.freepik.com/free-vector/code-typing-concept-illustration_114360-2937.jpg?size=626&ext=jpg&uid=R89088614&ga=GA1.1.528450336.1707044250&semt=ais"
              className=" object-cover xl:w-[500px] 2xl:w-[560px]  lg:w-[440px] w-[500px] px-4 lg:px-0 "
              alt=""
            />
          </div>
        </div>
      </div>

      {/* our services */}

      <div className="px-4 max-w-[350px] sm:max-w-[550px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto py-8 ">
        <h3 className="text-2xl xl:pl-10 lg:pl-0 sm:text-center  lg:text-3xl  py-8 font-semibold xl:text-[52px] xl:leading-[80px]">
          Services we Provide
        </h3>

        <div className="flex flex-wrap   justify-between  gap-4  lg:space-x-0  lg:gap-x-12 xl:gap-x-0 lg:gap-y-8 lg:justify-center  xl:justify-between   2xl:justify-center 2xl:gap-12 items-center py-8">
          <div className="bg-blue-500 text-white rounded-2xl  w-[250px] h-[300px] shadow-lg  p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <LuWebhook className="text-white mx-auto" size={150} />
            <p className="text-white text-center text-xl font-semibold py-2 ">
              Website
            </p>
          </div>

          <div className="bg-[#fff]  rounded-lg w-[250px]  h-[300px] shadow-lg   p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <MdComputer className="text-black mx-auto" size={150} />
            <p className="text-black text-center text-xl font-semibold py-2 ">
              Web Application
            </p>
          </div>

          <div className="rounded-lg bg-blue-500 w-[250px] h-[300px] shadow-lg   p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <CiMobile3 className="text-white mx-auto" size={150} />

            <p className="text-white text-center text-xl font-semibold py-2">
              Mobile App
            </p>
          </div>

          {/* <div className="bg-[#6967ce]   rounded-lg w-[250px] h-[300px] shadow-lg  p-6 cursor-pointer transition duration-300 transform hover:scale-105">
            <GiProcessor className="text-white" size={50} />

            <p className="text-white text-xl font-semibold py-2 ">
              Efficient Implementation Process
            </p>

            <p className="text-sm text-white ">
              Experience an efficient implementation process for swift digital
              integration.
            </p>
          </div> */}
        </div>
      </div>


     {/* pricing card */}

     <div className="flex flex-wrap justify-center items-center gap-10 px-4 max-w-[350px] sm:max-w-[550px] lg:max-w-[900px] xl:max-w-[1100px] 2xl:max-w-[1300px] container mx-auto py-12">
      <PricingCard
        title="Basic"
        price="€800"
        features={[
          "1 Basic Dynamic Website (*20 Pages with upto 2 custom functionality) + 1 Free Domain + 1 Free Hosting",
          "2 GB Bandwidth",
          "3 GB Storage",
          "1 Premium SSL Certificate",
          "Premium Support 10 A.M to 6 P.M (IST) + 1 Year Services Maintenance at 0$ Cost",
          'Search Engine Optimisation (SEO) - Normal Level For 1 Keyword',
          'Google Analytics Account Integration With Website',
          '3 Business Consultation',


        ]}
      />
      <PricingCard
        title="Pro"
        price="€1300"
        features={[
          "1 Normal Dynamic Website (*30 Pages with upto 3 custom functionality) + 1 Free Domain + 1 Free Hosting",
     '2 GB Bandwidth',
     '3 GB Storage',
     '1 Premium SSL Certificate',
     '1 Payment Gateway Integration With Website',
     'Premium Support 10 A.M to 7 P.M (IST) + 1 Year Services Maintenance at 0$ Cost',
     'Search Engine Optimisation (SEO) - Good Level (Domestic/ Local Level) for 2 Keywords',
     'Google Analytics Account Integration With Website',
     '5 Business Consultation',
     '1 Shared Project Manager',
     '1 Shared Account Manager',

     





        ]}
      />
      <PricingCard
        title="Enterprise"
        price="€2400"
        features={[
          "1 Classic Dynamic Website (*50 Pages with upto 5 custom functionality) + 1 Free Domain",
          "1 Hosting With *Unlimited Bandwidth",
          "10 GB SSD Hosting Storage",
          "1 Premium SSL Certificate",
          "1 Payment Gateway Integration With Website",
          'Premium Support 9 A.M to 8 P.M (IST) + 1 Year Services Maintenance at 0$ Cost',
          'Social Media Management (3 posts in a week on Facebook + Instagram)',
          'Search Engine Optimisation (SEO) - Best Level (Domestic Level) For 3 Keywords',
          '1 E-mail Campaigning / Month',
          ` 
          1 Facebook Advertisement / Month - Page Boost / Post Boost Management only`,
          `Google Analytics Account + Google Tag + Facebook Pixel + 1 API Integration With Website`,
          '7 Business Consultation',
          '1 Dedicated-Shared Project Manager Along With 2 Expert-Shared Developers/ Designers/ Marketers Team',
          '1 Business Website Management Dashboard (Standard Level)',
          'Business Profile/ Pages Creation on Popular Channels i.e Facebook + Instagram + Google + Twitter + LinkedIn.',




        ]}
      />
    </div>

    



      {/* newsletter */}

      <Newsletter />
    </div>
  );
};

export default Webdev;
