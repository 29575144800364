import React from 'react';

const WhatsappWidget = () => {
  return (
    <div className="fixed bottom-4 left-4 bg-green-500 p-3 rounded-full cursor-pointer">
      <a href="https://wa.me/918859505051" target="_blank" rel="noopener noreferrer">
        <img
          src="https://cdn-icons-png.flaticon.com/128/5968/5968841.png"
          alt="WhatsApp"
          className="w-10 h-10"
        />
      </a>
    </div>
  );
};

export default WhatsappWidget;
