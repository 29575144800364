import React from "react";
import { IoGitNetworkSharp } from "react-icons/io5";
import { AiOutlineGlobal } from "react-icons/ai";
import { SiPaloaltonetworks } from "react-icons/si";
import { DiAsterisk } from "react-icons/di";
import { TbTopologyStar3 } from "react-icons/tb";

const Mission = () => {
  return (
    <section className="">
      <div className="bg-[#fd636b] py-16">
        <div className="py-12 flex  flex-col lg:flex-row gap-10 justify-between items-center container mx-auto max-w-[350px] sm:max-w-[550px]  md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1100px] 2xl:max-w-[1300px]">
          <div className=" text-white ">
            <h5 className="text-xl xl:text-[36px] md:text-center   font-semibold mb-4 xl:leading-[50px] ">
          
              Our mission is to empower businesses globally by <span className="text-black">providing
              innovative and seamless workforce solutions.</span>  We are dedicated to
              delivering excellence through our new-age Solutions.

             
            </h5>

            <h5 className="text-xl xl:text-[36px] xl:leading-[50px] md:text-center font-semibold mb-4 text-customBlue mt-16">
              Our vision is to be the go-to partner for enterprises seeking
              transformative solutions and unparalleled business elevation.
            </h5>
          </div>

          <div></div>
        </div>
      </div>

      {/* cards */}

      <div className=" py-16 flex flex-wrap gap-8 justify-center xl:justify-between  container mx-auto max-w-[350px] sm:max-w-[550px]  md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1100px] 2xl:max-w-[1300px]">
        <div className="w-48 cursor-pointer bg-gray-100 p-4 flex flex-col justify-center items-center rounded-md shadow-md transform hover:scale-105 transition-transform duration-300">
          <IoGitNetworkSharp className="text-[#1bafd0] mb-2" size={36} />
          <h5 className="text-md font-semibold text-gray-800 text-center">
            Innovative Workforce Solutions
          </h5>
        </div>

        <div className="w-48 cursor-pointer bg-gray-100 p-4 flex flex-col justify-center items-center rounded-md shadow-md transform hover:scale-105 transition-transform duration-300">
          <AiOutlineGlobal className="text-[#fd636b] mb-2" size={36} />
          <h5 className="text-md font-semibold text-gray-800 text-center">
            Global Impact, Local Excellence
          </h5>
        </div>

        <div className="w-48 cursor-pointer bg-gray-100 p-4 flex flex-col justify-center items-center rounded-md shadow-md transform hover:scale-105 transition-transform duration-300">
          <SiPaloaltonetworks className="text-[#ffb900] mb-2" size={36} />
          <h5 className="text-md font-semibold text-gray-800 text-center">
            Client-Centric Collaboration
          </h5>
        </div>

        <div className="w-48 cursor-pointer bg-gray-100 p-4 flex flex-col justify-center items-center rounded-md shadow-md transform hover:scale-105 transition-transform duration-300">
          <DiAsterisk className="text-[#6967ce] mb-2" size={36} />
          <h5 className="text-md font-semibold text-gray-800 text-center">
            Proactive Risk Mitigation
          </h5>
        </div>

        <div className="w-48 cursor-pointer bg-gray-100 p-4 flex flex-col justify-center items-center rounded-md shadow-md transform hover:scale-105 transition-transform duration-300">
          <TbTopologyStar3 className="text-[#3be8b0] mb-2" size={36} />
          <h5 className="text-md font-semibold text-gray-800 text-center">
            Commitment to Excellence
          </h5>
        </div>
      </div>
    </section>
  );
};

export default Mission;
